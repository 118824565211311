.preamble {
	margin-bottom: $module-margin;
	padding-top: $module-margin;

	.media-left {
		padding-right: 30px;
	}

	p {
		font-weight: 500;
	}

	> .media-body {
		&.read-more {
			display: block;
			max-height: 160px;
			overflow: hidden;
			position: relative;
			width: 100%;

			&:after {
				position: absolute;
				bottom: 0;
				height: 100%;
				width: 100%;
				content: "";
				background: linear-gradient(to top, rgba(236, 239, 244, 1) 0%, rgba(236, 239, 244, 0) 100%);
				pointer-events: none;
			}
		}
	}

	.btn, .btn:active {
		outline: 0;
	}
}

@media(max-width: $screen-xs-max) {
	.preamble {
		margin-top: 60px;
	}
}
