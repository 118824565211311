.container {
	&.error-page {
		padding-top: 30px;
		display: table-cell;
		vertical-align: middle;

		.content {
				text-align: center;
				display: inline-block;
		}

		.title {
				font-size: 30px;
				margin-bottom: 40px;
		}
	}
}
