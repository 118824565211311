.body-static {
  padding-top: 0 !important;
}

.facebook-like {
  .well {
    background-color: #fff;
  }
}

.sweden-map {
  .img-responsive {
    padding-left: 40px;
    margin-top: 20px;
    max-height: 340px;
  }

  ul {
    margin-top: 20px;

    li {
      font-size: 13px;
      line-height: 22px;
    }
  }
}

.welcome-wrapper {
  margin-top: 30px;
}

.static-page {
  .background-container {
    background-color: #3d4d65 !important;
    background-position: center center;
    background-size: cover;
  }

  .navbar.navbar-inverse {
    background-color: rgba(0, 0, 0, 0);
    position: relative;
  }

  .jumbotron {
    background-color: rgba(0, 0, 0, 0) !important;
  }

  .head-jumbotron {
    color: #fff !important;
    margin-bottom: 0 !important;
    padding-top: 100px;

    .container {
      width: 900px;
    }

    h4 {
      color: #fff;
      line-height: 1.8em;
    }
  }

  &.welcome-page {
    .background-container {
      background-image: url("/img/gastrogate_start.jpg") !important;
    }

    .head-jumbotron {
      h4 {
        color: #fff;
        font-weight: lighter;
      }

      .container {
        width: 900px;
      }
    }
  }

  &.static-contact {
    .background-container {
      background-image: url("/img/lunch-middag.jpg") !important;
    }
  }

  &.static-connect {
    .background-container {
      background-image: url("/img/anslut-din-restaurang.jpg") !important;
    }

    h2 {
      margin-bottom: 50px;
    }

    h5 {
      display: inline-block;
    }

    ul {
      list-style-type: none;
      text-align: left;
    }

    .info-block {
      text-align: left;

      .icon {
        color: $orange;
        font-size: 3em;
        margin-left: 0;
      }
    }

    .connect-form {
      margin-top: 40px;
    }

    .well {
      margin-top: 24px;
    }
  }

  &.static-about {
    .background-container {
      background-image: url("/img/gastrogate-restaurangguide.jpg") !important;
    }

    .content-jumbotron {
      margin-bottom: 0 !important;
      min-height: 100px !important;

      > .container {
        max-width: 900px;
      }

      h2 {
        margin-bottom: 50px;
      }

      .info-block {
        text-align: left;

        .icon {
          color: $orange;
          font-size: 3em;
          margin-left: 0;
        }

        h4 {
          margin-top: 0;
        }

        p {
          color: $medium-blue;
          font-size: 0.9em;
        }

        li {
          color: $medium-blue;
          font-size: 0.9em;
        }

        table {
          color: $medium-blue !important;
          font-size: 0.9em;

          th {
            background-color: darken($body-bg, 7%);
          }

          td,
          th {
            vertical-align: text-top;
            padding: 3px;
            width: 20%;
          }
        }
      }
    }
    .download-jumbotron {
      min-height: 100px !important;
      background-color: #d9e0e9 !important;
      margin-bottom: 40px;
      padding-top: 20px;
    }
  }

  &.static-error {
    .background-container {
      background-image: url("/img/gastrogate-restaurangguide.jpg") !important;
    }
    .content-jumbotron {
      img {
        margin-top: 20px;
      }

      .info-block {
        text-align: left;

        ul.links {
          list-style-type: none;
          font-size: 0.9em;
          line-height: 1.6em;
          padding-left: 0;

          li {
            margin: 0;
          }
        }
      }
    }
  }
}

&.static-corona {
  .background-container {
    background-image: url("/img/jumbotron-corona.jpg") !important;
	}
	
	.content-jumbotron {
		@media (min-width: 700px) {
			.container {
				width: 700px;
			}
		}
	}
}

&.static-apps {
  .background-container {
    background-image: url("/img/gastrogate-app-2.png") !important;
  }
  .head-jumbotron,
  .download-jumbotron {
    p {
      font-size: 0.9em !important;
    }

    .app-buttons {
      margin-top: 30px;

      .googleplay-image {
        margin-left: 30px;
      }

      @media (max-width: $screen-xs-max) {
        .googleplay-image {
          margin-left: 0px;
        }
      }
    }
  }

  .content-jumbotron {
    margin-bottom: 0 !important;
    min-height: 100px !important;

    h2 {
      margin-bottom: 50px;
    }

    .info-block {
      text-align: left;

      .icon {
        color: $orange;
        font-size: 3em;
        margin-left: 0;
      }

      h4 {
        margin-top: 0;
      }

      p {
        color: $medium-blue;
        font-size: 0.9em;
      }
    }
  }

  .download-jumbotron {
    min-height: 100px !important;
    background-color: #d9e0e9 !important;
    margin-bottom: 40px;
    padding-top: 20px;

    .app-buttons {
      margin-top: 0px;
    }
  }
}

.jobs-jumbotron {
  .ul-header {
    margin-bottom: 5px;
  }

  ul {
    margin-bottom: 15px;
    li {
      margin-bottom: 5px;
    }
  }
}

@media (max-width: $screen-xs-max) {
  .static-page .head-jumbotron {
    padding-top: 15px;
  }

  .form-horizontal {
    .btn-primary {
      margin-top: 20px;
    }
  }

  .static-page.static-error .content-jumbotron .info-block {
    margin-top: 20px;
    text-align: center;
  }
}
