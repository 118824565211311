.react-placeholder {
	&:empty {
		border: 1px dashed rgba(0, 0, 0, .2);
		border-radius: $border-radius-base;
		cursor: wait;
		min-height: 100px;
		position: relative;

		&:after {
			animation: spinner 1s linear infinite;
			border: 2px solid rgba(0, 0, 0, .5);
			border-radius: 100%;
			border-bottom-color: transparent;
			border-top-color: transparent;
			content: '\0020';
			display: block;
			height: 30px;
			left: 50%;
			margin: -15px 0 0 -15px;
			position: absolute;
			top: 50%;
			width: 30px;
		}
	}

	&[id^="context-menu-"] {
		margin-top: 15px;
		min-height: 43px;
		min-width: 141px;
		float: right !important;
	}
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
