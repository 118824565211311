.btn {
	text-transform: uppercase;
	padding: 10px 18px;

	&.btn-lg {
		font-size: 14px;
		padding: 14px 40px;
	}

	&.btn-md {
		font-size: 13px;
	}

  &.btn-xxs {
		padding: 2px 5px;
    font-size: 10px;
    line-height: 1.4;
    border-radius: 3px;
    margin-top: 2px;
	}

	&.btn-unrounded {
		border-radius: 0;
	}

	&.btn-facebook, &.btn-gplus, &.btn-twitter {
		color: #FFF;
		font-weight: 500;
	}

	&.btn-rounded {
		background: transparent;
		border: 1px solid #CAD3DF;
		border-radius: 20px;
		color: #8F9DB0;
		font-size: 12px;
		font-weight: normal;
		padding-left: 30px;
		padding-right: 30px;
	}

	&.btn-default {
		color: $medium-blue;
		font-size: 0.9em;

		&:hover {
			background: #CAD3DF;
			color: #fff;
			border: 1px solid #CAD3DF;
		}
	}
}

.btn-social {
	[class^="icon-"] {
		margin-right: 4px;
	}
}

// gastrogate buttons
.btn-green {
	@include button-variant($btn-green-color, $btn-green-bg, $btn-green-border);
}

.btn-blue {
	@include button-variant($btn-blue-color, $btn-blue-bg, $btn-blue-border);
}

.btn-transparent {
	@include button-variant($btn-transparent-color, $btn-transparent-bg, $btn-transparent-border);
}

.btn-lime {
	@include button-variant($btn-lime-color, $btn-lime-bg, $btn-lime-border);
}

.btn-red {
	@include button-variant($btn-red-color, $btn-red-bg, $btn-red-border);
}

.btn-orange-inverse {
	@include button-variant($btn-orange-inverse-color, $btn-orange-inverse-bg, $btn-orange-inverse-border);
}

.btn-orange-inverse:hover {
	@include button-variant(#FFF, $orange, $orange);
}

.btn-blue-inverse {
	@include button-variant($btn-blue-inverse-color, $btn-blue-inverse-bg, $btn-blue-inverse-border);
}

.btn-blue-inverse:hover {
	@include button-variant(#FFF, $medium-light-blue, $medium-light-blue);
}

.btn-dropdown {
	@include button-variant($btn-dropdown-color, $btn-dropdown-bg, $btn-dropdown-border);
}

.btn-christmas {
	@include button-variant($btn-christmas-color, $btn-christmas-bg, $btn-christmas-border);
}

.btn-white {
	@include button-variant($btn-white-color, $btn-white-bg, $btn-white-border);
}

.btn-yellow {
	@include button-variant($btn-yellow-color, $btn-yellow-bg, $btn-yellow-border);
}

@include text-emphasis-variant('.btn-dropdown', $btn-dropdown-color);

// social media buttons
.btn-facebook {
	@include button-variant(#FFF, $facebook-color, darken($facebook-color, 5%));
}

.btn-gplus, .btn-google {
	@include button-variant(#FFF, $gplus-color, darken($gplus-color, 5%));
}

.btn-twitter {
	@include button-variant(#FFF, $twitter-color, darken($twitter-color, 5%));
}

.btn-instagram {
	@include button-variant(#FFF, $instagram-color, darken($instagram-color, 5%));
}

.btn-gg-primary {
  @include button-variant($btn-gg-primary-color, $btn-gg-primary-bg, $btn-gg-primary-border);
}

.btn-gg-primary-inverted {
  @include button-variant($btn-gg-primary-inverted-color, $btn-gg-primary-inverted-bg, $btn-gg-primary-inverted-border);
}

.btn-gg-red {
  @include button-variant($btn-gg-red-color, $btn-gg-red-bg, $btn-gg-red-bg);
}

.btn-gg-green {
  @include button-variant($btn-gg-green-color, $btn-gg-green-bg, $btn-gg-green-bg);
}

.btn-gg-grey {
  @include button-variant($btn-gg-grey-color, $btn-gg-grey-bg, $btn-gg-grey-bg);
}

.btn-gg-blue-inverted {
  @include button-variant($btn-gg-blue-inverted-color, $btn-gg-blue-inverted-bg, $btn-gg-blue-inverted-border);
}

.btn-gg-blue-light {
  @include button-variant($btn-gg-blue-light-color, $btn-gg-blue-liht-bg, $btn-gg-blue-light-border);
}
