.card-flex {
	margin-left: -15px;
	margin-right: -15px;
}

.card-flex, .card-flex > div[class*='col-'] {
	flex-wrap: wrap;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	flex: 1 1 auto;

	.flex-column {
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;

		.panel {
			position: relative;
			display: flex;
			flex-direction: column;
			align-content: stretch;

			> .panel-heading {
				font-family: 'Roboto Condensed', sans-serif;
				color: $dark-blue;
				font-size: 1.2em;
			}

			> .panel-body {
				display: flex;
				flex-grow: 1;
				flex-flow: wrap;

				> .media {
					margin-top: 10px;

					h4 {
						margin: 0;
						font-weight: 400;
						color: $medium-blue;
					}

					p {
						margin: 0;
						color: $medium-blue;
						font-size: .8em;
					}

					.rating {
						font-size: 11px;
					}
				}
			}
		}
	}
}

.christmas-ad, .deal, .popularity, .static, .subscriptions, .details {
	.panel {
		color: $dark-blue;
		border: none;

		&.panel-default {
			border: none;
		}

		> .panel-heading {
			padding: 20px;
			text-align: left;
			text-transform: uppercase;
			font-family: 'Roboto Condensed', sans-serif;
			color: $dark-blue;
			font-size: 1.2em;
		}

		> .panel-body {
			padding: 20px;
			text-align: left;

			p {
				font-size: .9em;
				line-height: 1.8em;
			}
		}

		> .panel-footer {
			background: #FFF;
			border: none;
			padding-bottom: 15px;
		}

		> img {
			border-top-left-radius: inherit;
			border-top-right-radius: inherit;
		}

		.badge {
			color: #FFF;
			background-color: $badge-color;
			border-radius: 20px 0 0 20px;
			font-family: 'Roboto Condensed', sans-serif;
			font-size: 12px;
			font-weight: 500;
			padding: 12px;
			position: absolute;
			right: 1px;
			top: 18px;
		}

		.media > a {
			color: $dark-blue;
		}

		h4 {
			font-family: 'Roboto Condensed', sans-serif;
			font-weight: 500;
			margin-top: 20px;
		}

		h5 {
			font-size: 13px;
			font-weight: 400;
			margin-bottom: 0;
			margin-top: 0;
		}

		small {
			font-size: 11px;

			> span {
				display: block;
				color: $orange;
				margin-top: 4px;
			}
		}

		.rating {
			font-size: 9px;

			[class^="icon-"]:before, [class*=" icon-"]:before {
				margin: 0;
				width: .9em;

			}
		}

		.btn-christmas {
			font-size: 13px;
		}
	}
}

.rating {
	color: $orange;
}

.static {
	.panel {
		background-position: center center;
		background-size: cover;

		> .panel-body {
			color: #FFF;
			padding: 30px;

			h3 {
				color: #FFF;
				margin-bottom: 0;
				line-height: 1.4em;
			}

			a {
				margin-top: 25px;
				padding-left: 30px;
				padding-right: 30px;
			}
		}
	}
}

.banner {
	display: block;
	margin-bottom: $module-margin;

	> img {
		border-radius: $border-radius-base;
	}
}

.christmas-ad {
	position: relative;

	.panel {
		&.panel-default {
			border: 1px solid #CAD3DF;
			border-radius: 5px;

			img {
				border-radius: 5px 5px 0 0;
			}
		}

		.badge {
			&.badge-christmas {
				background-color: $christmas-color;
				font-size: 12px;
				font-weight: bold;
			}
		}
	}
}

.christmas-link {
	.panel.panel-default {
		background-position: center center;
		background-size: cover;

		> .panel-body {
			text-decoration: none;

			h3 {
				color: white;
			}
		}
	}
}

.card {
	.panel {
		background-position: center center;
		background-size: cover;

		> .panel-body {
			padding: 30px;

			h3, p {
				margin-top: 0;
				color: white;
			}

			p {
				font-size: .9em;
				line-height: 1.8em;
			}
		}
	}
}

.form-box {
	.btn-lime {
		font-size: 9px;
		i {
			padding-left: 5px;
			padding-right: 5px;
		}
	}
}
