.modal-lg {

	.modal-header, .modal-footer {
		padding: 40px;
	}

	.modal-header {
		p {
			font-size: .9em;
			margin-bottom: 0;
		}

		.close {
			font-size: 30px;
			font-weight: normal;
		}
	}

	.modal-body {
		padding: 0;
	}
}

.modal-sm {
	.modal-content {
		> img {
			border-top-left-radius: inherit;
			border-top-right-radius: inherit;
		}
	}

	.modal-header {
		padding: 0;

		.close {
			font-size: 2em;
			font-weight: normal;
			position: absolute;
			right: 12px;
			top: 8px;
		}
	}

	.modal-body {
		padding: 20px 30px 0;

		h4 {
			font-size: 1.2em;
			font-weight: 600;
		}

		small {
			font-size: .7em;
		}

		.list-unstyled {
			font-size: .7em;

			h6 {
				font-weight: bold;
				margin-bottom: 7px;
				margin-top: 7px;
			}
		}

		.btn {
			margin-bottom: 20px;
		}
	}

	.modal-footer {
		padding: 20px 30px 30px;
	}
}

.modal {
	.close {
		z-index: 100;
	}
}

.my-tab {
	border-bottom: 1px solid #CAD3DF;
	margin-left: 0;
	padding-left: 40px;
	padding-right: 40px;

	> li {
		cursor: pointer;
		font-weight: 600;
		padding: 0 0 16px;
		text-transform: uppercase;

		&:not(:last-child) {
			margin-right: 40px;
		}

		&.active {
			border-bottom: 2px solid #D2335C;
		}
	}
}