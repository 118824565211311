@font-face {
    font-family: 'Roboto';
    src: url('../font/roboto-regular.eot');
    src: url('../font/roboto-regular.eot?#iefix') format('embedded-opentype'),
         url('../font/roboto-regular.woff') format('woff'),
         url('../font/roboto-regular.ttf') format('truetype'),
         url('../font/roboto-regular.svg#roboto') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('../font/roboto-condensed.eot');
    src: url('../font/roboto-condensed.eot?#iefix') format('embedded-opentype'),
         url('../font/roboto-condensed.woff') format('woff'),
         url('../font/roboto-condensed.ttf') format('truetype'),
         url('../font/roboto-condensed.svg#roboto-condensed') format('svg');
    font-weight: normal;
    font-style: normal;
}
