section {
	margin-bottom: $module-margin;

	&.well {
		h3, h4 {
			margin-top: 0;
		}
	}

	&.well-lg {
		height: 230px; // temp
		padding: 30px;

		small {
			font-size: .9em;
			font-weight: 300;
		}

		.form-control, .btn {
			margin-top: 20px;
			height: 45px;
		}

		.btn {
			width: 45px;
		}

		.list-unstyled {
			font-family: 'Roboto', sans-serif;
		}
	}

	h2 {
		margin-bottom: 34px;

		a, a:hover {
			color: $orange;
			cursor: pointer;
			text-decoration: underline dotted $orange;

			> .caret {
				border-top-width: 7px;
				border-right-width: 7px;
				border-left-width: 7px;
				margin-left: 4px;
			}
		}
	}

	.dropdown-menu {
		width: 220px;
		padding-bottom: 40px;
		padding-top: 40px;

		> li {
			margin-left: auto;
			margin-right: auto;
			text-align: left;
			width: 80%;

			> a {
				color: $dark-blue;
				font-weight: 600;
				padding-left: 0;
				padding-right: 0;
			}

			&:not(:last-child) {
				border-bottom: 1px dashed $border-color;
				padding-bottom: 10px;
			}

			&:not(:first-child) {
				padding-top: 10px;
			}
		}
	}
}
