$modal-border-radius: 5px;
$primary-font: "Roboto", sans-serif;
$blue-dark: rgb(17, 39, 60);
$primary: rgb(255, 153, 0);
$grey-light: rgb(244, 248, 245);

.react-tableplanner {
  .dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 5000;
  }

  .dialog, .dialog-modal {
    width: 400px;
    margin: 30px auto;
    background-color: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    border-radius: $modal-border-radius;
    //    overflow: hidden;
    position: absolute;
    left: 50%;
    right: 50%;
    transform: translate(-50%, 0%);

    font-family: $primary-font;

    @media (max-width: 750px) {
      width: 95%;
    }

    @media (max-width: 40em) {
      border-radius: 0;
      &.fullscreen {
        transform: none;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: 0;
          width: 100%;
      }
    }

    header {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      background-color: #f6f7f9;
      border-bottom: 1px solid #e5e5e5;
      border-top-left-radius: $modal-border-radius;
      border-top-right-radius: $modal-border-radius;
      
      h3 {
        color: #555;
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: .5px;
        margin: 0;
        padding-block: 5px;
      }
    }

    .dialog-msg {
      padding: 15px;
      padding-bottom: 40px;
      font-size: 0.9em;

      .payment-rules-text {
        margin-bottom: 10px;
      }
    }

    footer {
      border: none;
      border-top: 1px solid #e5e5e5;
      border-radius: 0;
      margin: 0;
      padding: 8px 10px;
      background-color: #f6f7f9;
      border-bottom-left-radius: $modal-border-radius;
      border-bottom-right-radius: $modal-border-radius;

      .controls {
        display: flex;
        justify-content: space-between;

        .action-buttons {
          direction: rtl;
        }

        .button {
          padding: 5px 15px;
          border-radius: 3px;
          margin-left: 3px;
          cursor: pointer;
          font-family: $primary-font;
          font-size: 0.9em;

          &:focus {
            outline: 0;
          }

          &.button-default {
            color: #5d5d5d;
            border: 0;

            &:hover {
              background-color: rgb(235, 234, 234);
            }
          }

          &.button-danger {
            background-color: #f44336;
            border: 1px solid #d32f2f;
            color: #f5f5f5;

            &:hover {
              background-color: darken(#f44336, 10%);
            }

            &.outline {
              background-color: rgb(248, 248, 248);
              color: #d32f2f;

              &:hover {
                background-color: rgb(250, 233, 233);
              }
            }
          }

          &.button-success {
            background-color: $blue-dark;
            border: 1px solid darken($blue-dark, 10%);
            color: white;

            &:hover {
              background-color: darken($blue-dark, 10%);
            }

            &.outline {
              background-color: white;
              border: 1px solid $blue-dark;
              color: $blue-dark;

              &:hover {
                background-color: $grey-light;
              }
            }
          }

          &.button-primary {
            background-color: $primary;
            border: 1px solid darken($primary, 5%);
            color: white;

            &:hover {
              background-color: darken($primary, 10%);
            }

            &.outline {
              background-color: white;
              color: $primary;

              &:hover {
                background-color: rgb(230, 235, 250);
              }
            }
          }
        }
        .button:disabled {
          cursor: not-allowed;
          pointer-events: none;
          opacity: 0.65;
          filter: alpha(opacity=65);
          box-shadow: none;
        }
      }
    }
  }
}
