.suggestbox {
	padding-right: 15px;
	position: absolute;
	top: ($navbar-height + 2px);
	width: 100%;
	z-index: $zindex-modal;

	ul {
		padding-left: 0;
	}

	> ul {
		@include box-shadow(0 6px 12px rgba(0, 0, 0, .175));
		background-color: $white;

		li {
			list-style: none;

			li {
				font-size: .85em;
				font-weight: bold;
				line-height: 28px;
				padding-left: 25px;
				padding-right: 25px;

				.typed, .text-danger {
					font-weight: normal;
				}
			}

			h6 {
				background-color: #D9E0E9;
				color: #8392A9;
				font-weight: 600;
				line-height: 36px;
				margin: 0;
				padding-left: 25px;
				padding-right: 25px;
			}
		}
	}
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  height: 40px;
  padding: 10px 20px;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
  -webkit-appearance: none;
}

.react-autosuggest__input:focus {
  outline: none;
}

.react-autosuggest__input::-ms-clear {
  display: none;
}

.react-autosuggest__container--open .react-autosuggest__input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
  display: block;
  position: relative;
  top: -1px;
  width: 100%;
  background-color: #fff;
  font-weight: 300;
  font-size: 14px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
	position: absolute;
	width: 100%;
  margin: 0;
  padding: 0;
	top: 1px;
	left: -1px;
  list-style-type: none;
	background-color: white;
	border: solid 1px $border-color;
}

.jumbotron {
	.react-autosuggest__suggestions-list {
		top: 42px;
	}

	.icon-search {
		color: $text-color !important;
		padding: 6px;
		z-index: 3;
	}
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 5px 20px;
	color: $text-color;
	text-align: left;
}

.react-autosuggest__suggestion--focused {
  background-color: #f5f5f5;
}
