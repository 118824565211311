.cookie-notification-bar {
	.main {
		background-color: $dark-blue;
		bottom: 0;
		left: 0;
		position: fixed;
		right: 0;
		z-index: 10000;

		.container {
			p {
				color: white;
				font-size: 12px;
				line-height: 18px;
				padding-bottom: 10px;
				padding-top: 10px;
				margin: 0;

				a {
					color: white;
					cursor: pointer;
					font-style: italic;
					font-weight: bold;
					text-decoration: underline;
				}
			}

			.btn {
				margin-bottom: 10px;
				margin-top: 10px;
			}
		}
	}

	#cookie-modal {
		p {
			margin-bottom: 30px;
		}

		.btn {
			margin-bottom: 30px;
			padding-left: 50px;
			padding-right: 50px;
		}
	}
}

@media (min-width: $screen-xs-max) {
	.cookie-notification-bar .container {
		div:first-child {
			padding-right: 0;
			padding-left: 0;
		}

		div:last-child {
			padding-left: 0;
			padding-right: 0;
			position: relative;
		}

		.btn {
			position: absolute;
			right: 0;
		}
	}
}