.lunch-wrapper {
  max-width: 600px;
  margin: 0 auto;

  .lunch-container {
    width: 100%;

    .lunch-day-container {
      .lunch_header {
        font-family: Roboto Condensed, sans-serif;
        background-color: #eceff4;
        height: 40px;
        line-height: 40px;
        padding-left: 30px;
        padding-right: 30px;
        margin-bottom: 5px;
        font-size: 1em;
        text-transform: uppercase;
      }

      .lunch-item {
        font-family: Roboto, sans-serif;
        padding-left: 30px;
        padding-right: 30px;
        font-size: 0.9em;
        line-height: 22px;
        margin-bottom: 10px;

        .lunch-item--title {
          color: #8493a8 !important;
        }

        .lunch-item--price {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}
