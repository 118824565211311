//#### Gastrogate variables

//==== colours

//---- basic

$christmas-color: #D2335C;
$cross-color: #D0021B;
$deal-color: #A234D5;
$heart-color: #D2335C;
$star-color: #ADBACB;

$green: #04BE5B;
$orange: #FF9800;
$white: #FFF;
$blue: #3c5f88;

$chat-bubble-left: #ECEFF4;
$chat-bubble-right: rgb(215, 245, 255);

$light-blue: #D9E0E9;

//---- social media

$facebook-color: #3B5998;
$gplus-color: #DC4838;
$instagram-color: #517FA4;
$twitter-color: #55ACEE;

//==== modules

$module-margin: 30px;

// old!
$dark-blue: #3D4D65;
$medium-dark-blue: #54657E;
$medium-blue: #8493A8;
$medium-light-blue: #ADB9CA;
$subscribe-color: #ECEFF4;
$footer-color: #8493A8;
$border-color: #CAD3DF;
$light-grey-text: #B4BCCB;

$gg-primary: rgb(255, 153, 0);
$gg-blue-light: rgb(193, 219, 220);
$gg-blue-dark: rgb(17, 39, 60);
$gg-grey: rgb(209, 214, 211);
$gg-grey-light: rgb(244, 248, 245);
$gg-grey-dark: rgb(93, 95, 94);
$gg-black: rgb(51, 51, 51);
$gg-green: rgb(121, 189, 116);
$gg-red: rgb(218, 83, 79);

//#### Bootstrap variables

//==== basic

$body-bg: #ECEFF4;

$text-color: #3D4D65;

$link-color: $orange;

$font-size-h2: 2.1em;
$font-size-h3: 1.7em;

//==== badge

$badge-color: $deal-color;

//==== btn

$btn-default-color: #3D4D65;
$btn-default-border: #CAD3DF;

$btn-primary-bg: $orange;

//---- gastrogate

$btn-green-color: $white;
$btn-green-bg: $green;
$btn-green-border: darken($btn-green-bg, 5%);

$btn-blue-color: $white;
$btn-blue-bg: $blue;
$btn-blue-border: darken($btn-blue-bg, 5%);

$btn-transparent-color: $white;
$btn-transparent-bg: rgba(black, 0);
$btn-transparent-border: rgba(black, 0);

$btn-white-color: $footer-color;
$btn-white-bg: $white;
$btn-white-border: darken($footer-color, 5%);

$btn-lime-color: $white;
$btn-lime-bg: #9DBF16;
$btn-lime-border: darken($btn-lime-bg, 5%);

$btn-red-color: $white;
$btn-red-bg: #D2335C;
$btn-red-border: darken($btn-red-bg, 5%);

$btn-orange-inverse-color: $orange;
$btn-orange-inverse-bg: $white;
$btn-orange-inverse-border: $orange;

$btn-yellow-color: rgb(242,138,4);
$btn-yellow-bg: rgb(255,252,236);
$btn-yellow-border: darken($btn-yellow-bg, 5%);

$btn-blue-inverse-color: $footer-color;
$btn-blue-inverse-bg: transparent;
$btn-blue-inverse-border: $footer-color;

$btn-dropdown-color: #CAD3DF;
$btn-dropdown-bg: #ECEFF4;
$btn-dropdown-border: $btn-dropdown-color;

$btn-christmas-color: $white;
$btn-christmas-bg: $christmas-color;
$btn-christmas-border: $christmas-color;

$success-bg: rgba(4, 190, 91, 0.4);
$warning-bg: rgba(255, 152, 0, 0.4);
$error-bg: rgba(210, 51, 92, 0.4);

$btn-gg-primary-color: white;
$btn-gg-primary-bg: $gg-primary;
$btn-gg-primary-border: darken($gg-primary, 5%);

$btn-gg-primary-inverted-color: $gg-primary;
$btn-gg-primary-inverted-bg: white;
$btn-gg-primary-inverted-border: darken($gg-primary, 5%);

$btn-gg-blue-light-color: $gg-blue-dark;
$btn-gg-blue-liht-bg: $gg-blue-light;
$btn-gg-blue-light-border: $gg-blue-light;

$btn-gg-blue-inverted-color: $gg-grey-light;
$btn-gg-blue-inverted-bg: $gg-blue-dark;
$btn-gg-blue-inverted-border: $gg-blue-dark;

$btn-gg-green-color: white;
$btn-gg-green-bg: $gg-green;
$btn-gg-green-border: darken($gg-green, 5%);

$btn-gg-red-color: $white;
$btn-gg-red-bg: $gg-red;
$btn-gg-red-border: darken($gg-red, 5%);

$btn-gg-grey-color: $white;
$btn-gg-grey-bg: $gg-grey-dark;
$btn-gg-grey-border: darken($gg-grey-dark, 5%);

//==== label

$label-default-bg: $white;

$label-color: #3D4D65;

//==== modal

$modal-header-border-color: $white;
$modal-footer-border-color: $white;

$modal-sm: 370px;
$modal-lg: 770px;

//==== navbar

$navbar-height: 60px;
$navbar-inverse-bg: #3D4D65;
$navbar-inverse-link-color: $white;

//==== panel

$panel-default-heading-bg: #CAD3DF;

//==== well

$well-bg: $light-blue;
$well-border: $light-blue;
