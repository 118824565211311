@import "variables";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "vendor/roboto-font";
@import "vendor/gastroicons";
@import "vendor/react-datetime";
@import "vendor/react-datepicker/datepicker";
@import "vendor/react-widget";
@import "vendor/sweetalert";
@import "vendor/jquery-tokenize";
@import "vendor/lity";
@import "navbar";
@import "sidebar";
@import "helpers/text";
@import "gastrogate/mixins";
@import "gastrogate/headers";
@import "gastrogate/sections";
@import "gastrogate/cards";
@import "gastrogate/buttons";
@import "gastrogate/forms";
@import "gastrogate/footer";
@import "search/filterbox";
@import "search/resultbox";
@import "gastrogate/labels";
@import "gastrogate/checkbox";
@import "gastrogate/preamble";
@import "gastrogate/carousel";
@import "gastrogate/static";
@import "gastrogate/subscribe";
@import "gastrogate/information";
@import "gastrogate/user";
@import "gastrogate/quicklinks";
@import "search/suggestbox";
@import "gastrogate/modal";
@import "sidebar/infobox";
@import "gastrogate/error";
@import "gastrogate/cookies";
@import "gastrogate/tableplanner";
@import "gastrogate/tableplanner_modal";
@import "gastrogate/lunch";
@import "gastrogate/notifications";
@import "react";

.btn-xs-small {
  top: 0;
  padding: 1px 5px;
  text-transform: capitalize;
  font-size: 10px;
  margin-top: 3px;
}

// iOS
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}

@media (max-width: $screen-sm-max) {
  .text-center-sm {
    text-align: center;
  }
}

.scroll-disable {
  overflow-x: auto;
  overflow-y: hidden;
}

.has-feedback-left {
  input.form-control {
    padding-left: 44px;
    padding-right: 12px;
  }

  .form-control-feedback {
    left: 0;
  }
}

.reservation-success {
  margin-left: -10px;
  margin-right: -10px;

  .checkmark {
    border-radius: 50%;
    border: 3px solid #00bf58;
    display: inline-block;
    height: 60px;
    margin-bottom: 15px;
    margin-top: 15px;
    padding-top: 10px;
    width: 60px;

    .icon-check {
      color: #00bf58;
      font-size: 30px;
    }
  }
}

.big-loading-icon:after {
	display: inline-block;
	content: '\0020';
	width: 55px;
	height: 55px;
	position: relative;
	top: 2px;
	border: 4px solid rgba(0, 0, 0, 0.5);
	border-radius: 100%;
	border-top-color: rgba(255, 255, 255, 0.1);
	border-bottom-color: rgba(255, 255, 255, 0.1);
	animation: spinner 1s linear infinite;
}

#christmas-modal {
  .modal-header {
    padding: 5px 10px 5px 5px;
  }

  .modal-body {
    font-family: "Roboto", sans-serif;
    font-size: 0.9em;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 35px;

    h2 {
      font-size: 2.5em;
    }

    .well {
      background-color: #eceff4;
      border: none;

      h4 {
        font-family: "Roboto Condensed", sans-serif;
        font-size: 17px;
      }

      .form-control,
      .btn {
        font-size: 0.9em;
      }
    }
  }
}

.wrapper {
  margin-right: auto;
  margin-left: auto;
}

.no-left-padding {
  padding-left: 0;
}

.no-right-padding {
  padding-right: 0;
}

hr {
  border-top: 1px solid #cad3df;
  margin-bottom: 30px;
  margin-top: 10px;
}

body {
  font-family: "Roboto", sans-serif;
  padding-top: 62px;
}

.wrapper,
.container {
  .jumbotron {
    color: $dark-blue;
    background-color: $light-blue;
    border-radius: 0;
    margin-bottom: 20px;
    margin-top: 0;
    min-height: 400px;
    text-align: center;

    h1 {
      font-size: 3.125em;
      line-height: 1.35em;
    }

    p {
      font-size: 1em;
    }

    &.welcome {
      h5 {
        font-size: 1.2em;
      }

      a {
        color: $link-color;
        text-decoration: underline;
      }
    }

    .input-group {
      margin-bottom: 15px;
      margin-top: 15px;

      > .input-group-addon {
        background-color: white;
      }

      .btn-primary {
        padding-left: 30px;
        padding-right: 30px;
      }

      .search-box {
        @include placeholder($medium-blue);
        font-weight: lighter;
        font-style: italic;
        border-left: 0;
        box-shadow: none;
      }
    }
  }
}

#register-modal {
  .input-group {
    > .input-group-addon {
      background-color: white;
      border-left: 0;
    }

    > input {
      @include placeholder($medium-blue);
      border-right: 0;
      box-shadow: none;
    }
  }
}

#message,
#error_message {
  top: 50%;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 2000;

  .alert {
    text-align: center;
    width: 100%;
  }
}

@media (max-width: $screen-xs-max) {
  aside {
    padding-left: 15px !important;
  }
  main {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .welcome {
    &.container {
      padding-left: 10px;
      padding-right: 10px;

      .row {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}

@media (max-width: $screen-sm-max) {
  aside {
    padding-right: 15px !important;

    .banner {
      padding-left: 0;
    }
  }
}
