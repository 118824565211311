.filter {
	border-color: $border-color;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	margin-bottom: $module-margin;

	div.favourite {
		padding-top: 6px;
		.label {
			margin-right: 5px;
			font-size: 1em;
			text-transform: uppercase;
			font-family: "Roboto Condensed", sans-serif;
			font-weight: 500;
			padding: 0;
		}
		.icon-heart {
			&.favourite {
				position: relative;
				top: 5px;
				color: $heart-color;
				font-size: 1.4em;
				line-height: 25px;
			}
		}

		.field input {
			font-size: 24px;
		}
	}

	.panel-body {
		padding: 0 40px 0;

		.form-horizontal {
			.multiple-select-box {
				width: 100%;

				ul.Dropdown {
					overflow-y: scroll;
					max-height: 312px;
				}

				ul.TokensContainer {
					border-color: #CAD3DF;
					border-radius: 5px;
					height: inherit;
					padding: 6px 3px 3px 3px;

					li.Placeholder {
						line-height: 11px;
					}

					li.Token {
						background-color: #ECEFF4;
						border: none;
						border-radius: 15px;
						color: #374355;
						height: inherit;
						margin: 0 5px 0 0;
						padding: .314em 1em .314em 1em;

						span {
							font-size: 14px !important;
							line-height: 100%;
						}

						a.Close{
							color: #CE0A24;
							cursor: pointer;
							font-family: "Roboto", sans-serif !important;
							font-size: 1.2em !important;
							font-weight: bold;
							margin-left: 2px;
						}
					}
				}
			}

			.form-group {
				margin-bottom: 0;
				min-height: 64px;

				&:not(:last-child) {
					border-bottom: 1px solid $border-color;
				}

				.remove-badge {
					font-size: 0.9em;
					position: relative;
					float: left;
					background-color: #ECEFF4;
					border: none;
					color: #374355;
					border-radius: 15px;
					padding: .314em 1em .314em 1em;

					&:not(:first-child) {
						margin-left: 15px;
					}

					.remove-badge-cross {
						color: #CE0A24;
						cursor: pointer;
						font-family: "Roboto", sans-serif;
						font-size: 1.2em;
						font-weight: bold;
						margin-left: 2px;
					}
				}

				.control-label {
					color: $dark-blue;
					font-size: 1.1em;
					font-weight: 500;
					line-height: 50px;
					padding-left: 0;
					text-align: left;
					text-transform: uppercase;
					font-family: "Roboto Condensed", sans-serif;
				}

				.input-group {
					margin-top: 14px;

					.rw-multiselect-taglist {
						li {
							background-color: #ECEFF4;
							border: none;
							margin-top: 3px;
							color: #374355;
							border-radius: 15px;
							padding: .114em 1em .214em 1em;
						}
					}

					.rw-tag-btn {
						margin-left: 5px;
						color: #CE0A24;
						position: relative;
						font-weight: bold;
						font-size: 1.2em;
					}
				}

  				.more-filters {
					line-height: 40px;
					padding-bottom: 14px;
					padding-top: 14px;

					.btn {
						padding: 10px 22px;
					}

					.counter {
						font-size: .9em;
						font-weight: 600;
					}
				}
			}

			.buttons {
				margin-right: -20px;
			}
		}

		.pull-right {
			margin-bottom: 25px;
		}
	}

	.panel-footer {
		@include clearfix;
		background-color: $light-blue;
		padding-right: 10px !important;

		.list-inline > li {
			padding-left: 15px;
			padding-right: 15px;

			select.form-control {
				margin-top: 3px;
			}
		}
	}

	.map {
		.map-infowindow {
			min-width: 175px !important;

			.map-title {
				text-decoration: none;
			}
			.map-homepage-link {
				color: #79ACE7;
				float: right;
				text-decoration: none;
			}
			.map-rating {
				color: #FF9800;
				float: left;
			}
		}
	}

	.fullwidth {
		width: 100%;
	}

	.rw-widget:not(.rw-calendar) {
		min-width: 100%;
	}

	.rw-dropdownlist-picker {
		background-color: #ECEFF4;
		border-left: solid 1px #CDD6E1;
	}

	.no-left-padding {
		padding-left: 0
	}
	.no-right-padding {
		padding-right: 0
	}
}

.rating-filter {
	padding-top: 6px;
}

@media(max-width: $screen-xs-max) {
	.filter {
		.xs-filter-buttons {
			margin-bottom: 30px;
		}

		.filterButton, .mapButton {
			background-color: $light-blue;
			border: 0;
			border-bottom: solid 1px $medium-light-blue;
			font-size: 0.8em;
			height: 50px;
			line-height: 30px;
			position: absolute;
			text-transform: uppercase;
			width: 50%;
		}
		.filterButton {
			border-right: solid 1px $medium-light-blue;
			left: 0;
		}
		.mapButton {
			right: 0;
		}

		.map {
			margin-top: 50px;
		}

		.panel-footer {
			display: none;
		}

		.panel-body {
			.sticky-bottom-button {
				bottom: 0;
				margin-top: 5px;
				margin-bottom: 10px;

				.btn {
					width: 100%;
				}
			}

			.form-horizontal {
				.rating {
					.icon {
						font-size: 0.6em;
					}
				}

				.form-group {

					margin-top: 10px;
					padding-bottom: 10px;
					min-height: 0;

					.control-label {
						line-height: 15px;
					}
				}
				div.col-xs-12 {
					padding-left: 0;
					padding-right: 0;

					&:not(:last-child) {
						margin-bottom: 10px;
					}

					.input-group {
						margin-top: 0;
					}
				}
			}
		}
	}
}
