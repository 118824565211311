.navbar {
	border: 0;
	border-radius: 0;
	margin-bottom: 0;

	.navbar-collapse {
		padding-left: 0;
		padding-right: 0;
	}

	.navbar-nav {
		display: inline-block;
		float: none;
		vertical-align: top;
		text-transform: uppercase;
		font-size: 0.78em;
	}

	.navbar-header {
		padding-left: 0;

		> .navbar-toggle {
			margin-left: 15px;
			margin-right: 0;
		}
	}

	.navbar-collapse {
		text-align: center;
	}

	.navbar-brand {
		color: white;
		padding-top: 8px;
		padding-left: 0;

		> a {
			padding-left: 0;
		}

		> img {
			max-height: 30px;
			margin-top: 6px;
		}

		@media (min-width: $screen-sm-min) {
			> img {
				max-height: 50px;
				margin-top: 4px;
			}
		}
	}

	.search-box-navbar {
		.search-box-navbar-wrapper {
			@include clearfix;
			width: 100%;
			position: relative;
		}

		.search-box {
			height: 62px;
			left: 70px;
			right: 65px;
			position: absolute;

			.form-group {
				margin-bottom: 0;
			}
		}

		.search-input {
			@include placeholder($dark-blue);
			font-weight: 400;
			box-shadow: none;
		}

		.app-icon {
			float: left;
			width: 70px;
			height: 62px;
			padding: 0 10px;

			img {
				border: 0;
				float: left;
				height: 36px;
				margin-top: 10px;
				padding-right: 5px;
				padding-left: 8px;
			}
		}

		@media (min-width: $screen-md-min) {
			.app-icon {
				border-right: 1px solid #DDD;

				img {
					border: 0;
					float: left;
					height: 42px;
					margin-top: 8px;
					padding-right: 5px;
					padding-left: 8px;
				}

			}
		}

		.save-icon {
			float: right;
			width: 55px;
		}

		.user-icon {
			float: right;
			width: 65px;
			padding-right: 8px;

			i {
				color: white;
				cursor: pointer;
				font-size: 1.8em;
				margin-right: 12px;
				padding-top: 18px;
			}
		}

		.icon-search, .icon-star-empty {
			color: #8493A8;
			font-size: 1.3em;
			line-height: 61px;
			padding: 5px 15px;
		}

		.icon-star-empty {
			color: $star-color;
			margin-right: 20px;
		}

		@media(max-width: $screen-sm-max) {
			.search-box {
				padding-bottom: 10px;
				padding-top: 14px;
			}

			.icon-search {
				line-height: 30px;
			}

			.app-icon {
				margin-left: 3px;

				img {
					border: 0;
					float: left;
					height: 36px;
					margin-top: 14px;
					padding-right: 5px;
					padding-left: 8px;
				}
			}

			}
		}

		@media(min-width: $screen-md) {
			.search-box-navbar-wrapper {
				background-color: #FFF;
				border-bottom: 1px solid #DDD;
			}

			.search-input {
				border: 0;
				border-radius: 0;
				height: 62px;
				font-size: 1.5em;
				margin-top: 0;
			}
		}
	}

&.navbar-inverse {
	background-color: $dark-blue;

	.navbar-nav {

		#btn-login, #btn-register {
			font-size: 0.983em;
			margin-top: 12px;
			min-width: 100px;
			padding: 10px;
		}

		&.login {
			margin: 0 -15px;
		}

		&.login > li > a {
			padding: 0;

			div {
				color: #FFF;
				font-size: 0.8em;
				line-height: 48px;
				text-transform: uppercase;
				width: 100%;
			}
		}
	}

	.btn-login, .btn-register {
		height: 100%;
	}

	@media(max-width: $screen-sm-max) {
		.search-box-navbar {
			padding-right: 0;
		}
	}
}

.welcome {
	&.user-icon {
		width: 55px;

		i {
			color: white;
			cursor: pointer;
			font-size: 1.8em;
			line-height: 62px;
			margin-right: 12px;
		}
	}
}

.logged-in-container {
	background-color: #FFF;
	width: 279px;
	z-index: 1000;

	&.logged-in-container-mobile {
		width: 100%;
		left: 0;
		right: 0;
	}

	> ul {
		padding: 15px;

		> li {
			line-height: 40px;
			list-style-type: none;
			text-decoration: none;

			&:not(:last-child) {
				border-bottom: 1px dashed $border-color;
			}

			&.username {
				color: $light-grey-text;
				text-decoration: none;
			}

			a {
				color: $dark-blue;
				cursor: pointer;
				font-weight: 600;
				line-height: 40px;
				text-decoration: none;
				text-transform: uppercase;
			}
		}
	}
}

.logged-in-box {
	color: #FFF;

	i.icon-down-open-big, i.icon-user {
		position: relative;
		top: 5px;
	}

	i.icon-user {
		font-size: 1.7em;
		margin-right: 15px;
	}

	i.icon-down-open-big {
		cursor: pointer;
		font-size: 1.4em;
		font-weight: bold;
		top: 6px;
	}

	> li {
		// background-color: $medium-dark-blue;
		padding: 0;
		padding-left: 10px;
		width: 100%;

		> a {
			padding: 0;

			div {
				height: 62px;
				line-height: 62px;
			}
		}
	}
}

.login {
	> li {
		padding: 0;
	}

	.login-container {
		background: #FFF;
		padding: 15px;
		width: 300px;
		z-index: 1000;

		hr {
			margin-bottom: 10px;
		}

		.btn-social:not(:last-child) {
			margin-bottom: 10px;
		}
		.icon-right-open-mini {
			color: #fff;
			margin-top: 6px;
			padding-left: 6px;
			font-size: 18px;
		}

		#no-account-btn {
			cursor: pointer;
		}

		&.dropdown-menu {
			left: 0;
			right: auto;
		}

		.form-group {

			.btn {
				width: 100%;

				a {
					color: #fff;
					text-decoration: none;
				}
			}

			.form-group {

				.form-control {
				height: auto;
				font-size: 13px;
				}
			}
		}

		.remember {
			border: 0;
			margin-bottom: 10px;

			.checkbox {
				margin-top: 0;
			}

			span {
				position: relative;
				text-transform: none;
				top: 4px;
			}
		}

		.input-group {
			> .input-group-addon {
				background-color: $subscribe-color;
				border-left: 0;
			}

			> input {
				@include placeholder($light-grey-text);
				background-color: $subscribe-color;
				border-right: 0;
				box-shadow: none;
				height: 36px;
			}
		}

		.icon-mail, .icon-lock {
			color: #8493A8;
			padding-top: 6px;
			padding-left: 6px;
			font-size: 18px;
		}

		input[type=submit] {
			text-transform: uppercase;
		}

		.link-row {
			margin-bottom: 15px;

			.link {
				font-size: 10px;
			}
		}
	}
}

.login {
	.login-container-mobile {
		width: 100%;
		margin-left: 0;

		&.dropdown-menu {
			left: 0;
			right: 0;
		}

		@media(max-width: $screen-sm) {
			.login-container-mobile {
				width: 100%;

				&.dropdown-menu {
					left: 0;
				}
			}
		}
	}
}

.navbar-right {
	@include clearfix;

	&.login {
		> .col-md-12 {
			padding: 0;
		}
	}
}

#register-modal, #login-modal {
	.modal-content {
		border: 0;

		.modal-header {
			padding: 15px 15px 15px 30px;

			.modal-title {
				text-transform: uppercase;
			}

			p {
				color: $light-grey-text;
				margin: 10px 0 0 0;
			}
		}

		.modal-body {
			padding: 15px 30px;

			.btn {
				font-size: .9em;
			}

			.btn-social {
				margin-bottom: 15px;
				text-align: left;
				padding-top: 12px;
				border: 0;
				height: 42px;

				[class^="icon-"] {
					margin-left: -4px;
					padding-right: 5px;
				}
			}

			.btn-create-account {
				font-weight: 500;
				text-transform: uppercase;
				height: 42px;
			}
		}

		.modal-footer {
			padding: 15px 30px;
			p {
				color: $light-grey-text;
				font-size: .8em;
			}
		}
	}
}

.btn-no-account {
	cursor: pointer;
}

@media(max-width: $screen-xs-max) {
	#register-modal, #login-modal {
		.modal-body div[class*='col-']:last-child {
			border-top: solid 1px $border-color;
			padding-top: 15px;
		}

		.modal-body {
			div[class*='col-'] {
				&.social-buttons {
					margin-top: 15px;
				}
			}
		}
	}
}
