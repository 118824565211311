.quicklinks {
	text-align: center;

	&.col-md-10 {
		margin-bottom: 0;
	}

	.dropdown {
		display: inline-block;

		.dropdown-menu {
			margin-top: -12px;
			padding: 20px 10px;
		}

		.dropdown-menu:after {
			content:"";
			position: absolute;
			background: url('/img/mouse-down.gif') no-repeat center center;
			width: 50px;
			height: 50px;
			top: 32px;
			margin-left: 130px;
			opacity:0.6;
		}

		.btn {
			border-radius: 50px;
			margin-right: 6px;
			margin-bottom: 12px;
			padding: 12px 30px;
			text-transform: uppercase;
		}

		ul {
			max-height: 325px;
			overflow: auto;
		}
	}
}
