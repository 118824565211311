.subscribe-module {
	background-image: url('/img/bg_subscribe.png');
	padding: 0;
	margin-top: 20px;
	margin-bottom: 40px;

	h2, p {
		color: #FFF;
		margin: 0;
		padding-top: 10px;
	}

	p {
		font-weight: 200;
	}

	.container {
		height: inherit;
		position: relative;

		.subscribe-module-text {
			height: inherit;
			padding: 15px 0 0 0;

			.form-control {
				height: 42px;
			}
		}
	}

	.subscribe-module-chef-img {
		bottom: 0;
		max-width: 250px;
		position: absolute;
	}
}

.subscriptions-radio-group {
	clear: both !important;
	label, button {
		clear:both !important;
	}
}

.subscription-link {
	color: #3D4D65;
}

@media (min-width: $screen-md-min) {
	.subscribe-module {
		margin-top: 60px;

		.container {
			padding-left: 100px;
			padding-right: 100px;

			.subscribe-module-text {
				padding: 35px 0 20px 10px !important;
			}
		}
	}
}

@media (max-width: $screen-sm-max) {
	.subscribe-module {
		text-align:center;
		padding-top: 10px;
		padding-bottom: 20px;
		margin-top: 0;
	}

}
