.label-default {
	font-size: .9em;
	font-weight: normal;

	> .icon-cancel {
		color: $cross-color;
		cursor: pointer;
		font-size: 1.2em;
		font-weight: 700;
	}
}
