.infobox {
	margin-bottom: $module-margin;
	padding: 30px;

	p {
		font-size: .85em;
		font-weight: 300;
		margin-top: 25px;

		&:last-child {
			margin-bottom: 45px;
		}
	}
}