$info-color: #1890ff;
$success-color: #52c41a;
$error-color: #f5222d;
$warning-color: #faad14;

/** Notification-dialog **/
.gg-notification-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999999;

  .gg-notification {
    width: 400px;
    margin: 50px auto;
    padding: 10px 16px;
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    position: absolute;
    left: 50%;
    right: 50%;

    &.info {
      background-color: lighten($info-color, 40%);
    }
    &.success {
      background-color: lighten($success-color, 50%);
    }
    &.error {
      background-color: lighten($error-color, 40%);
    }
    &.warning {
      background-color: lighten($warning-color, 40%);
    }

    transform: translate(-50%, -50%);

    @-webkit-keyframes fadeIn {
      from {
        opacity: 0;
        margin: 0 auto;
      }
      to {
        opacity: 1;
        margin: 50px auto;
      }
    }
    @-moz-keyframes fadeIn {
      from {
        opacity: 0;
        margin: 0 auto;
      }
      to {
        opacity: 1;
        margin: 50px auto;
      }
    }
    @keyframes fadeIn {
      from {
        opacity: 0;
        margin: 0 auto;
      }
      to {
        opacity: 1;
        margin: 50px auto;
      }
    }

    -webkit-animation: fadeIn 1s ease;
    -moz-animation: fadeIn 1s ease;
    animation: fadeIn 1s ease;

    @media (max-width: 750px) {
      width: 350px;
    }

    .gg-notification-msg {
      font-size: 15px;
      color: #333;
      display: flex;

      .icon {
        &.info {
          color: $info-color;
        }
        &.success {
          color: $success-color;
        }
        &.error {
          color: $error-color;
        }
        &.warning {
          color: $warning-color;
        }
        margin-right: 8px;
        line-height: 8px;
        margin-top: 3px;
      }
    }
  }
}
