.filter-checkboxes {
	padding-top: 15px;

	.checkbox-inline {
		padding-left: 0;
		width: 22%;

		> input[type="checkbox"] {
			display: none;

			+ .icon-ok {
				border: 1px solid $border-color;
				border-radius: $border-radius-base;
				color: transparent;
				height: 20px;
				margin-right: 4px;
				width: 20px;
			}

			&:checked + .icon-ok {
				color: $cross-color;
			}
		}

		.title {
			font-size: .9em;

			.rating {
				display: inline-block;
			}
		}
	}

	a {
		color: #C8D1DC;

		&:hover {
			cursor: pointer;
		}
	}
}