.pagination {
	> .active {
		> span {
			background-color: #8392A9;
			border-color: #8392A9;
		}

		> span:hover {
			background-color: #54657E;
			border-color: #54657E;
		}
	}

	> li {

		> a {
			color: #54657E;
		}

		> a:hover {
			color: #54657E;
			background-color: #ECEFF4;
		}
	}
}

.restaurant-anchor {
	display: block;
	margin-top: -75px;
	padding-bottom: 75px;
}

.panel {
	&.no-search-result {
		border-color: $border-color !important;
		min-height: 250px;
		padding-top: 40px;
		text-align: center;
	}

	&.search-result {
		border-color: $border-color;
		color: $dark-blue;
		font-size: 16px;

		.panel-body {
			padding: 0;

			.search-result-header {
				@include clearfix;
				border-bottom: 1px solid $border-color;

				.thumbnail {
					background-color: $border-color;
					border: 0;
					border-radius: 3px 0 0 0;
					height: 159px;
					margin-bottom: 0;
					padding: 0;
					overflow: hidden;
					cursor: pointer;

					.like {
						background-color: #FFF;
						border-radius: 50%;
						color: $heart-color;
						cursor: pointer;
						font-size: 1.0em;
						left: 0;
						margin: 15px;
						padding: 2px 6px;
						position: absolute;
						top: 0;

						i {
							font-size: 1.3em;
							position: relative;
							top: 5px;
						}

					}

					.zoomin {
						bottom: 0;
						color: #FFF;
						cursor: pointer;
						font-size: 1.5em;
						margin: 5px;
						position: absolute;
						right: 0;
					}
				}

				[id^="context-menu"] .dropdown {
					margin-right: 20px;
					margin-top: 20px;
					position: absolute;
					right: 0;
					top: 0;

					.btn {
						background-color: $orange;
						border-color: darken($orange, 5%);
						color: white;
						font-size: 0.8em;

						&:hover {
							color: white;
							background-color: darken($orange, 10%);
							border-color: darken($orange, 17%);
						}

						.caret {
							margin-left: 4px;
						}
					}

					.dropdown-toggle {
						.icon-right-open {
							font-size: 0.8em;
						}
					}

					> ul {
						min-width: 220px;
						&.dropdown-menu {
							padding: 15px;
							li {
								list-style-type: none;

								&:not(:last-child) {
									border-bottom: 1px dashed $border-color;
								}

								&.disabled a {
									color: rgb(173, 185, 202);
									cursor: default;
								}

								a, &.dropdown-header {
									color: $dark-blue;
									cursor: pointer;
									font-weight: 500;
									line-height: 30px;
									text-decoration: none;
									text-transform: uppercase;

									.context-menu-subtitle {
										padding-left: 15px;
									}
								}

								&.dropdown-header {
									font-size: inherit;
									font-weight: bolder;
									cursor: default;
								}
							}
						}
					}
				}

				.restaurant-details {
					padding-top: 10px;

					> h4 {
						font-weight: 400;
						margin-bottom: 0;

						> a {
							color: inherit;
							text-decoration: none;
						}
					}

					.address {
						font-size: 0.9em;
						font-weight: 500;
						padding: 4px 0 0 0;
					}

					.opening-hours-popover {
						font-size: 12px;
					}

					.opening-hours-popover-header {
						font-weight: bold;
					}

					.popover {
						min-width: 342px;
						border: none;

						.arrow {
							border: none;
						}
					}

					.opening-hours {
						border: none;
						border-radius: 15px;
						clear: both;
						color: #FFF;
						float: left;
						font-size: .7em;
						margin-top: 6px;
						padding: .314em 1em .314em 1em;
						width: auto;

						.icon-clock {
							font-size: 1.1em;
							position: relative;
							top: 2px;
						}

						&.open {
							background-color: #9DBF16;
						}

						&.soon {
							background-color: #FFDA2E;
						}

						&.closed {
							background-color: #CA3058;
						}

						&.noinfo {
							background-color: #ADB9C2;
						}

					}

					.categories {
						color: $medium-light-blue;
						font-size: 0.9em;
						padding: 4px 0 0 0;
					}

					.rating {
						color: $orange;
						float: left;
						font-size: 0.8em;
						padding: 10px 0 0 0;
					}

					.likes, .rating-comments {
						color: $medium-light-blue;
						float: left;
						font-size: 0.8em;
						padding: 5px 0 0 15px;
					}

					.deal {
						bottom: 0;
						color: $deal-color;
						cursor: pointer;
						font-size: 0.9em;
						font-weight: 600;
						margin-right: 15px;
						position: absolute;
						right: 0;

						.icon-tag {
							font-size: 1.2em;
						}
					}
				}
			}

			.search-result-body, .search-result-more-content {
				font-size: 14px;
				line-height: 22px;
				overflow-y: hidden;
				padding: 20px;

				.lunch-row {
					&:not(:last-child) {
						padding-bottom: 10px;
					}
				}
			}

			.search-result-read-more {
				border-top: 1px dashed $border-color;
				cursor: pointer;
				font-size: 14px;
				color: $medium-light-blue;
				text-transform: uppercase;
				padding: 15px;
			}

			.search-result-more-content {
				display: none;
			}
		}
	}
	&.favourite {
		border-color: $heart-color;
	}
}

.special-food-icon {
	margin-bottom: 2px;
}

.search-results {
	h1 {
		padding-bottom: 10px;
	}
}

.wrap-box {
	max-height: 80px;
	position: relative;
	overflow: hidden;
}

.wrap-box .wrap-box-fade {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	text-align: center;
	margin: 0;
	padding: 30px 0 5px 0;
	color: #FFF;
	background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #FFF);
}

.searchModal {
	.modal-body {
		padding-left: 0;
		padding-right: 0;

		.text-above {
			margin-bottom: 15px;

			.alacarte_header {
				font-family: "Roboto Condensed", sans-serif;
				background-color: #ECEFF4;
				height: 40px;
				line-height: 40px;
				padding-left: 30px;
				padding-right: 30px;
				margin-bottom: 5px;
				font-size: 1em;
				text-transform: uppercase;
			}
			.alacarte_dish {
				font-family: "Roboto", sans-serif;
				padding-left: 30px;
				padding-right: 30px;
				font-size: 0.9em;
				line-height: 22px;

				.title, .price {
					color: $medium-dark-blue;
					font-weight: bold;
				}

				.price {
					float: right;
				}

				.subtitle {
					color: $footer-color;
				}

				.row {
					margin-bottom: 10px;
				}
			}
		}

		.text-below {
			margin-top: 10px;
		}

		.rating-block {
			margin-bottom: 15px;
		}
	}
}

.modal-body {
	.control-required {
		color: #CE0A24;
		font-size: 0.9em;
		font-weight: bold;
	}
}

.alacarte-modal {
	.modal-body {
		.customHeader {
			margin-bottom: 30px;
			padding-left: 30px;
		}
	}
	.subTitleAbove{
		padding-left: 30px;
		padding-right: 30px;
		padding-bottom: 10px;
	}
}

.book-modal {
	color: $dark-blue;
	font-family: "Roboto Condensed", sans-serif;

	.modal-body {
		label {
			font-weight: normal !important;
		}
	}

	.deals {
		border: 2px solid $border-color;
		border-radius: 4px;

		.deal {
			padding: 2px 15px 2px 15px;
		}

		.deal:not(:last-child) {
			border-bottom: 2px solid $border-color;
		}

		.deal-title {
			font-weight: bold;
		}

		.deal-body {
			font-size: 0.9em;
		}
	}
}

.deal-modal, .rating-modal, .subscribe-modal {
	.modal-body {
		.text-above {
			margin-bottom: 15px;
		}
		.radio {
			margin-top: 0;
			margin-bottom: 5px;
		}
	}
}

.rating-modal {
	.modal-body {
		.rating-block {
			margin-bottom: 15px;
		}
	}
	.icon-star-1, .icon-star-o {
		color: $orange;
	}
}

.deal-modal {
	.modal-body {
		.deal {
			.deal-body {
				font-weight: bold;
				margin-top: 15px;
			}
			.deal-conditions {
				font-style: italic;
				margin-top: 15px;
			}
		}
	}
}

.rating-modal, .subscribe-modal, .searchModal {
	.modal-header {
		position: absolute;
		right: 0;
		z-index: 100;
	}
}

#favourite-modal > .modal-dialog {
	.modal-header {
		position: absolute;
		right: 0;
		z-index: 100;
	}

	.modal-body {
		overflow: auto;
		padding: 0;

		.restaurant-details {
			background: #43BD84 url('/img/bg_favorite.jpg');
			border-bottom-left-radius: $border-radius-base;
			border-top-left-radius: $border-radius-base;
			color: #FFF;
			float: left;
			min-height: 400px;
			padding: 30px;

			.h3 {
				color: #FFF;
				margin-top: 8px;
				line-height: 1.2em;
			}

			p {
				font-size: 1em;
			}

			.rating {
				color: #F59D24;
			}
		}

		.login-details {
			float: right;
			padding: 30px 20px 30px 20px;

			.btn {
				width: 100%;
			}

			p {
				font-size: 0.9em;
			}

			hr {
				margin-bottom: 15px;
				margin-top: 15px;
			}

			.cancel-btn {
				margin-top: 0;
			}
		}
	}
}

.lity-image img {
	border: solid 4px #FFF !important;
	border-radius: 5px;
}

@media(max-width: $screen-xs-max) {
	.restaurant-details {
		padding-bottom: 10px;
	}
}

@media(max-width: $screen-xs-max) {
	#favourite-modal > .modal-dialog {
		.modal-body {
			overflow: auto;
			padding: 0;

			.restaurant-details {
				min-height: 100px;
				max-height: 150px;
			}
		}
	}

	.react-searchresults {
		margin-top: 60px;
	}

	.panel {
		&.search-result {
			font-size: 13px;

			.panel-body {

				.search-result-header {
					padding-bottom: 5px;
					.thumbnail {
						height: 161px;
					}

				}

				.btn-group > .dropdown-toggle:not(:first-child) {
					border-radius: 4px;
				}
			}
		}
	}
}

@media (min-width: $screen-sm-min) {
	.thumbnail img {
		height: 100%;
		width: auto;
	}
}
