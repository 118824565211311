section {
	.carousel {
		.carousel-control {
			background: $border-color;
			border-radius: 50%;
			color: $dark-blue;
			display: inline-block;
			height: 35px;
			line-height: 35px;
			opacity: 1;
			position: static;
			text-shadow: none;
			width: 35px;

			&.left {
				margin-right: 10px;
			}

			&.right {
				margin-left: 10px;
			}
		}
	}
}

.popularity-carousel {
	.carousel-control {
		font-size: 14px;
		line-height: 37px;
	}
}
