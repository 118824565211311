footer {
	border: 1px solid #CAD3DF;
	color: $footer-color;
	background-color: #FFF;
	border-radius: $border-radius-base;
	margin-bottom: 20px;

	.links {
		> a {
			color: $dark-blue;
			font-size: 1em;
			font-weight: 500;
			text-decoration: none;
			text-transform: uppercase;
		}
	}

	.upper-section {
		@include clearfix;
		padding: 40px;

		.list-inline {
			margin-bottom: 40px;

			> li:not(:first-child) {
				border-left: 1px solid $border-color;
				padding-left: 29px;
			}

			> li:not(:last-child) {
				padding-right: 30px;
			}
		}

		@media(max-width: $screen-md-max) {
			.list-inline {

				img {
					width: 90%;

				}
			}
		}

		@media(max-width: $screen-xs-max) {
			.list-inline {
				text-align: center;

				> li:not(:first-child) {
					border-left: 0;
					padding-left: 0;
				}

				> li:not(:last-child) {
					padding-right: 0;
				}

				.logo {
					margin: 0 auto;
				}

				.links {
					margin-top: 30px;
				}

			}
		}

		.form-control, .btn {
			height: 45px;
			margin-bottom: 25px;
		}

		.btn {
			width: 45px;
		}

		.form-control {
			@include placeholder($light-grey-text);
			background-color: $subscribe-color;
			border-color: $border-color;
		}

		.social-icons {
			margin-top: 30px;

			> a {
				margin-right: 10px;

				&:hover {
					text-decoration: none;

					> .icon-facebook {
						background-color: $facebook-color;
					}

					> .icon-instagram {
						background-color: $instagram-color;
					}

					> .icon-twitter {
						background-color: $twitter-color;
					}
				}

				> i {
					background-color: $footer-color;
					color: #FFF;
					display: inline-block;
					font-size: 1.3em;
					padding: 2px;
					padding-left: 6px;
					padding-top: 5px;
					height: 30px;
					width: 30px;
				}
			}
		}

		h4 {
			font-size: 1.2em;
			margin-bottom: 20px;
			margin-top: 0;

		}

		p, small {
			font-weight: 300;
			line-height: 1.6em;
		}
	}

	.lower-section {
		@include clearfix;
		border-top: 1px solid $border-color;
		font-size: 13px;
		padding: 20px 40px;

		.list-inline {
			margin-bottom: 0;

			li:hover .icon-heart {
				color: #800;
			}

			> .copy {
				text-align: right;
			}

			> .heart {
				text-align: left;
			}

			@media (max-width: $screen-xs-max) {
				> .copy, > .heart {
					display: block;
					text-align: center;
				}

				> .heart {
					padding-bottom: 10px;
				}
			}
		}
	}
}
