.my-pages {
	.panel-heading {
		text-transform: uppercase;
	}

	.panel-body:not(:last-child) {
		border-bottom: 1px solid $border-color;

		.icon-minus-circled {
			color: #D74059;
			font-size: 1.4em;
			padding-left: 4px;
			padding-right: 4px;
			vertical-align: middle;
		}

		ul {
			margin-top: 13px;
		}

		li {
			font-size: .9em;

			> .icon-minus-circled {
				cursor: pointer;
			}
		}
	}

	#subscriptions, .settings, .my-info {
		margin-top: 20px;

	}

	.subscriptions, .details {
		.panel {
			> .panel-body {
				padding-bottom: 60px;
			}
		}
	}
}
