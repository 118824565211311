.input-group, .form-group {
	.form-control {
		border-color: $border-color;
		@include placeholder($medium-blue);
	}

	&.has-error  {
		.form-control {
			border-color: #a94442;
		}
	}
	&.has-success  {
		.form-control {
			border-color: #3c763d;
		}
	}

	.search-box {
		background: #FFFFFF;
		border-radius: 5px;
		min-height:42px;

	}

	label {
			display: inline-block;
			font-weight: 500;
			font-size: 13px;
	}

	.dropdown-menu {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		margin-top: 0;
		width: 100%;
	}

	.input-group-btn > .btn {
		padding-left: 7px;
		padding-right: 7px;
	}
}
