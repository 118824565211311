.information-module {
	background-color: #ff9900;
	padding: 0;
	margin-top: 20px;
  margin-bottom: 40px;
  text-align: center;
  color: white;
  padding-top: 40px;
  padding-bottom: 40px;

	h2, p {
		color: #FFF;
		margin: 0;
		padding-top: 10px;
	}

	p {
		font-weight: 200;
	}

	.container {
		height: inherit;
		position: relative;

		.information-module-text {
			height: inherit;
			padding: 15px 0 0 0;

			.form-control {
				height: 42px;
			}
		}
	}

}

@media (min-width: $screen-md-min) {
	.information-module {
		margin-top: 60px;

		.container {
			padding-left: 100px;
			padding-right: 100px;

			.information-module-text {
				padding: 35px 0 20px 10px !important;
			}
		}
	}
}

@media (max-width: $screen-sm-max) {
	.information-module {
		text-align:center;
		padding-top: 10px;
		padding-bottom: 20px;
		margin-top: 0;
	}

}
