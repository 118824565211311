h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	color: $dark-blue;
	text-transform: uppercase;
}

h1, h2, h3, h4, h5, .h1, .h2, .h3, .h4, .h5 {
	font-family: 'Roboto Condensed', sans-serif;
	font-weight: 500;
}

h1, .h1 {
	font-size: 2em;
	line-height: 36px;
}

h2, .h2 {
	font-size: 2em;
	line-height: 38px;
}

h3, .h3 {
	font-size: 1.500em;
	line-height: 1.625em;
}

h4, .h4 {
	font-size: 1.188em;
	line-height: 1.500em;
}

h5, .h5 {
	font-size: 1.063em;
	line-height: 1.375em;
}

.headline {
	font-size: 3.125em;
	line-height: 2.350em;
}

p {
	font-size: 14px;
	line-height: 22px;
}
