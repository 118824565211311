.react-tableplanner,
.tableplanner-modal {
  margin-bottom: 40px;

  .spinner-button {
    cursor: wait;
    &:after {
      margin-right: 10px;
    }
  }

  .modal-header {
    padding: 5px;
  }

  .modal-footer {
    padding-top: 10px;
  }

  .modal-body {
    padding: 20px 30px 20px !important;
  }

  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    text-transform: inherit;
  }

  h2.booking-number {
    margin-bottom: 20px;
    background-color: pink;
    padding: 7px 0 7px 0;
  }

  .groupBox {
    border: #3c5a86 1px solid;
    border: #a7bbd7 1px solid;
    border: #CAD3DF 1px solid;
    border-radius: 4px;
    font-size: 13px;
    margin: 15px 0;
    flex: 1;

    .form-control {
      &.error {
        border-color: #900;
        background-color: #fceaf1;
      }
    }

    .groupBox--title {
      position: relative;
      top: -0.6em;
      margin-left: 1em;
      display: inline;
      background-color: white;
      padding-left: 5px;
      padding-right: 5px;
    }

    &.error {
      border-color: #900;

      .groupBox--title {
        color: #900;
      }
    }

    .groupBox--content {
      padding: 0 10px 10px 10px;

      display: flex;
      flex-direction: column;

      .checkbox {
        margin-top: 0;
        margin-bottom: 5px;
      }

      .groupBox--item-wrapper {
        display: flex;
        gap: 10px;

        .groupBox--item-title {
          width: 250px;
        }

        .groupBox--item-formcontrol {
          font-size: 11px;
          padding: 3px;
          height: auto;
        }
      }
    }
  }

  .tableplanner-restaurants-container {
    flex-wrap: wrap;
//    margin:5px;
    display:flex;
    flex-direction:row;
//    padding:5px;

    .restaurant-header {
      margin: 0;
      padding: 0 15px;

      h3 { 
        margin-top: 5px;
      }
    }

    .tableplanner-restaurants-container--card {
      max-width: 350px;
      background-color: #F6F6F6;
      border-radius: 5px;
      margin: 5px;
      padding: 5px;
      flex:1 1 auto;

      .tableplanner-groupedtimes-type-container {
        border: 0;
      }
    }
  }

  .toggle-btn {
    cursor: pointer;
  }

  .checkbox {
    label {
      font-size: 13px;
    }
  }

  .required {
    color: #a94442;
  }

  .tableplanner-information-alert {
    padding: 10px 15px;
  }

  .tableplanner-descriptions {
    .descriptions--item:not(:last-child) {
      padding-bottom: 5px;
      margin-bottom: 5px;
      border-bottom: 1px solid #bee8ef;
    }
  }

  .persons-block {
    select {
      display: block;
    }
    hr {
      margin-bottom: 15px;
    }

    .persons {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-right: -5px;
    }

    .btn-persons {
      margin-right: 5px;
      margin-bottom: 5px;
      flex: 1 1 calc(33.33333% - 10px);
    }
  }

  .times-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    .btn-time {
      position: relative;
      padding: 10px 17px;

      @media (max-width: 540px) {
        flex-basis: calc(50% - 5px);
      }

      .markup {
        position: absolute;
        top: -5px;
        right: -5px;
        background-color: #FF9900;
        color: white;
        height: 13px;
        width: 13px;
        display: flex;
        justify-content: center;
        font-size: 11px;
        border-radius: 50%;
        text-transform: lowercase;
        font-family: times;
        font-weight: bold;
      }
    }
  }

  .btn-full-width {
    width: 100%;
    font-size: 14px;
    .tableplanner-type-description {
      font-size: 11px;
      font-weight: 200;
      font-style: italic;
    }
  }

  .terms-paragraphs {
    font-size: 14px;
  }

  .react-datepicker {
    width: 100%;

    .react-datepicker__month-container {
      width: inherit;
    }
  }

  .btn {
    margin-bottom: 4px !important;
  }

  .btn.disabled,
  .btn[disabled],
  fieldset[disabled] .btn {
    background-color: rgb(219, 219, 219);
    border-color: rgb(199, 199, 199);
    color: rgba(0, 0, 0, 0.5);
  }

  .cancel-reservation-success {
    margin-top: 10px;
  }

  .tableplanner-groupedtimes-type-container {
    border: 1px #e0e0e0 solid;
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 10px;

    .tableplanner-groupedtimes-section-container:not(:last-child) {
      margin-bottom: 15px;
    }

    h1, h2, h3, h4, h5, h6 {
      margin: 0;
    }
  }

  .tableplanner-breadcrumbs {
    margin-top: 13px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;

    .btn-step:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 0;
    }

    .btn-step:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .btn-step {
      flex: 1;
      border-radius: 0;
      background-color: white;
      color: black;
      padding: 7px 10px;
      line-height: 1;
      border: 0;
      text-align: center;
      cursor: pointer;

      &[disabled] {
        color: #b3b3b3;
        &:hover {
          cursor: not-allowed;
        }
      }
    }

    .btn-step-active {
      border-bottom: solid 4px $gg-blue-dark;
      color: black;
    }
  }

  .btn-cancel-booking {
    display: inline-block;
    padding: 5px 10px;
    margin-top: 1rem;
  }

  .btn-loading {
    cursor: wait;
    &:after {
      margin-left: 10px;
    }
  }
  .btn-loading:after,
  .loadng-icon:after {
    display: inline-block;
    content: "\0020";
    width: 15px;
    height: 15px;
    position: relative;
    top: 2px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 100%;
    border-top-color: #fff;
    border-bottom-color: #fff;
    -webkit-animation: spinner 1s linear infinite;
    -moz-animation: spinner 1s linear infinite;
    -o-animation: spinner 1s linear infinite;
    animation: spinner 1s linear infinite;
  }
  .loadng-icon {
    display: inline-block;
  }
  .loadng-icon__dark:after {
    border-left-color: #888;
    border-right-color: #888;
  }

  .box {
    border: 1px solid #ddd;
    border-radius: 3px;
    background: #fff;
    margin: 5px 0 20px;
    position: relative;
    padding: 5px;
    font-size: 12px;

    .item:not(:last-child) {
      padding-bottom: 5px;
    }
  }

  table {
    font-size: 12px;
  }

  .result-box {
    background-color: #35435b;
    border: solid 1px #aaa;
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
    margin-bottom: 40px;
    margin-top: 40px;
    padding: 15px;

    h4 {
      color: #fff;
    }
  }

  .reservation-info-row {
    display: flex; 
    .reservation-info-label {
      width: 100px;
    }
    .reservation-info-data {

    }
  }
}

.cancel-reservation-wrapper {
  font-size: 14px;

  .booking-info-wrapper {
    .row {padding: 4px 0 }
    .row:nth-child(odd) {background: #EFEFEF}

    .button-container {
      display: flex;
    }

    @media (max-width: 400px) {
      font-size: 13px;

      .button-container {
        justify-content: space-between;
      }
    }
  }
}

.btn-disabled,
.btn-full,
.btn-blocked,
.btn-disabled:hover,
.btn-full:hover,
.btn-blocked:hover {
  text-decoration: line-through;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow {
  margin-left: -8px;
  position: absolute;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
}
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before {
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -8px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  border-top: none;
  border-bottom-color: #f0f0f0;
}
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow {
  bottom: 0;
  margin-bottom: -8px;
}
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before {
  border-bottom: none;
  border-top-color: #fff;
}
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before {
  bottom: -1px;
  border-top-color: #aeaeae;
}

.react-datepicker-wrapper {
  display: inline-block;
}

.react-datepicker {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}
.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 10px;
}
.react-datepicker-popper[data-placement^="top"] {
  margin-bottom: 6px;
}
.react-datepicker-popper[data-placement^="right"] {
  margin-left: 8px;
}
.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}
.react-datepicker-popper[data-placement^="left"] {
  margin-right: 8px;
}
.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  padding-top: 8px;
  position: relative;
}
.react-datepicker__header--time {
  padding-bottom: 8px;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem;
}

.react-datepicker__navigation {
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  width: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
}
.react-datepicker__navigation--previous {
  left: 10px;
  border-right-color: #ccc;
}
.react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}
.react-datepicker__navigation--next {
  right: 10px;
  border-left-color: #ccc;
}
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 80px;
}
.react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}
.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.react-datepicker__navigation--years-previous {
  top: 4px;
  border-top-color: #ccc;
}
.react-datepicker__navigation--years-previous:hover {
  border-top-color: #b3b3b3;
}
.react-datepicker__navigation--years-upcoming {
  top: -4px;
  border-bottom-color: #ccc;
}
.react-datepicker__navigation--years-upcoming:hover {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
}
.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -72px;
  top: 0;
}
.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 70px;
  overflow-x: hidden;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + calc(1.7rem / 2));
  overflow-y: scroll;
  padding-right: 30px;
  width: 100%;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  padding: 5px 10px;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #216ba5;
  color: white;
  font-weight: bold;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__day {
  cursor: pointer;
}
.react-datepicker__day:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}
.react-datepicker__day--today {
  font-weight: bold;
  background-color: $gg-blue-light;
  border-radius: 0.3rem;
}
.react-datepicker__day--today:hover {
  background-color: rgb(168, 216, 235);
}
.react-datepicker__day--highlighted {
  border-radius: 0.3rem;
  background-color: #3dcc4a;
  color: #fff;
}
.react-datepicker__day--highlighted:hover {
  background-color: #32be3f;
}
.react-datepicker__day--highlighted-custom-1 {
  color: magenta;
}
.react-datepicker__day--highlighted-custom-2 {
  color: green;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  border-radius: 0.3rem;
  background-color: $gg-blue-dark;
  color: #fff;
}
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover {
  background-color: #1d5d90;
}
.react-datepicker__day--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #2a87d0;
  color: #fff;
}
.react-datepicker__day--keyboard-selected:hover {
  background-color: #1d5d90;
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
  background-color: rgba(33, 107, 165, 0.5);
}
.react-datepicker__month--selecting-range
  .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range) {
  background-color: #f0f0f0;
  color: #000;
}
.react-datepicker__day--disabled {
  cursor: default;
  color: #ccc;
}
.react-datepicker__day--disabled:hover {
  background-color: transparent;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem;
}
.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover {
  cursor: pointer;
}
.react-datepicker__year-read-view:hover
  .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover
  .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover
  .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover
  .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow {
  border-top-color: #ccc;
  float: right;
  margin-left: 20px;
  top: 8px;
  position: relative;
  border-width: 0.45rem;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae;
}
.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover {
  cursor: pointer;
}
.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover {
  background-color: #ccc;
}
.react-datepicker__year-option:hover
  .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover
  .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}
.react-datepicker__year-option:hover
  .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover
  .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}
.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: inline-block;
  height: 0;
  outline: 0;
  padding: 0;
  vertical-align: middle;
}
.react-datepicker__close-icon::after {
  background-color: #216ba5;
  border-radius: 50%;
  bottom: 0;
  box-sizing: border-box;
  color: #fff;
  content: "\D7";
  cursor: pointer;
  font-size: 12px;
  height: 16px;
  width: 16px;
  line-height: 1;
  margin: -8px auto 0;
  padding: 2px;
  position: absolute;
  right: 7px;
  text-align: center;
  top: 50%;
}

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;
}
.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}
@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}
.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}
.react-datepicker__portal .react-datepicker__navigation {
  border: 0.81rem solid transparent;
}
.react-datepicker__portal .react-datepicker__navigation--previous {
  border-right-color: #ccc;
}
.react-datepicker__portal .react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}
.react-datepicker__portal .react-datepicker__navigation--next {
  border-left-color: #ccc;
}
.react-datepicker__portal .react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}

html,
body,
div {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

/* CSS talk bubble */
.talk-bubble-wrapper {
  display: flex;
  flex-direction: column;
  background-color: #F9F9F9;
  padding: 15px;
  border-radius: 4px;

  @media (max-width: 500px) {
    margin-top: 30px;
  }

  .talk-bubble {
    display: inline-block;
    position: relative;
    width: 300px;
    height: auto;
    background-color: $chat-bubble-right;
    margin-bottom: 5px;
    font-size: 0.8rem;

    &.restaurant {
      background-color: $chat-bubble-left;
    }

    @media (max-width: 500px) {
      width: 50vw;
    }
  }
  .talk-bubble--from-name {
    font-size: 11px;
  }
  .border{
    border: 8px solid #666;
  }
  .round{
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
  }

  /* Right triangle placed top left flush. */
  .tri-right.border.left-top:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: -40px;
    right: auto;
    top: -8px;
    bottom: auto;
    border: 32px solid;
    border-color: #666 transparent transparent transparent;
  }
  .tri-right.left-top:after{
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: -20px;
    right: auto;
    top: 0px;
    bottom: auto;
    border: 22px solid;
    border-color: $chat-bubble-right transparent transparent transparent;
  }

  /* Right triangle, left side slightly down */
  .tri-right.border.left-in:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: -40px;
    right: auto;
    top: 30px;
    bottom: auto;
    border: 20px solid;
    border-color: #666 #666 transparent transparent;
  }
  .tri-right.left-in:after{
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: -20px;
    right: auto;
    top: 38px;
    bottom: auto;
    border: 12px solid;
    border-color: $chat-bubble-right $chat-bubble-right transparent transparent;
  }

  /*Right triangle, placed bottom left side slightly in*/
  .tri-right.border.btm-left:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: -8px;
    right: auto;
    top: auto;
    bottom: -40px;
    border: 32px solid;
    border-color: transparent transparent transparent #666;
  }
  .tri-right.btm-left:after{
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: 0px;
    right: auto;
    top: auto;
    bottom: -20px;
    border: 22px solid;
    border-color: transparent transparent transparent lightblue;
  }

  /*Right triangle, placed bottom left side slightly in*/
  .tri-right.border.btm-left-in:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: 30px;
    right: auto;
    top: auto;
    bottom: -40px;
    border: 20px solid;
    border-color: #666 transparent transparent #666;
  }
  .tri-right.btm-left-in:after{
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: 38px;
    right: auto;
    top: auto;
    bottom: -20px;
    border: 12px solid;
    border-color: $chat-bubble-right transparent transparent lightblue;
  }

  /*Right triangle, placed bottom right side slightly in*/
  .tri-right.border.btm-right-in:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: auto;
    right: 30px;
    bottom: -40px;
    border: 20px solid;
    border-color: #666 #666 transparent transparent;
  }
  .tri-right.btm-right-in:after{
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: auto;
    right: 38px;
    bottom: -20px;
    border: 12px solid;
    border-color: $chat-bubble-right $chat-bubble-right transparent transparent;
  }

  .tri-right.border.btm-right:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: auto;
    right: -8px;
    bottom: -40px;
    border: 20px solid;
    border-color: #666 #666 transparent transparent;
  }
  .tri-right.btm-right:after{
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: auto;
    right: 0px;
    bottom: -20px;
    border: 12px solid;
    border-color: $chat-bubble-right $chat-bubble-right transparent transparent;
  }

  /* Right triangle, right side slightly down*/
  .tri-right.border.right-in:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: auto;
    right: -40px;
    top: 30px;
    bottom: auto;
    border: 20px solid;
    border-color: #666 transparent transparent #666;
  }
  .tri-right.right-in:after{
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: auto;
    right: -20px;
    top: 38px;
    bottom: auto;
    border: 12px solid;
    border-color: $chat-bubble-right transparent transparent $chat-bubble-right;
  }

  /* Right triangle placed top right flush. */
  .tri-right.border.right-top:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: auto;
    right: -40px;
    top: -8px;
    bottom: auto;
    border: 32px solid;
    border-color: #666 transparent transparent transparent;
  }
  .tri-right.right-top:after{
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: auto;
    right: -20px;
    top: 0px;
    bottom: auto;
    border: 20px solid;
    border-color: $chat-bubble-right transparent transparent transparent;
  }
  .tri-right.right-top.restaurant:after{
    border-color: $chat-bubble-left transparent transparent transparent;
  }

  /* talk bubble contents */
  .talktext{
    padding: 1em;
    text-align: left;
    line-height: 1.5em;
  }
  .talktext p{
    /* remove webkit p margins */
    -webkit-margin-before: 0em;
    -webkit-margin-after: 0em;
  }
}
